import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import NavbarPhone from "./NavbarPhone";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="navbar-desktop">
      <ul className="list-desktop">
        <li>
          <Link to="/">About</Link>
        </li>
        <li>
          <Link to="/graphic">Graphic Design</Link>
        </li>
        <li>
          <Link to="/media">Media plan</Link>
        </li>
        <li>
          <Link to="/others">Others</Link>
        </li>
      </ul>

      <img
        src="assets/menu.png"
        alt=""
        className="menu"
        onClick={() => setIsOpen(!isOpen)}
      />

      <NavbarPhone setIsOpen={setIsOpen} isOpen={isOpen} />
    </div>
  );
}

export default Navbar;
