import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import SlideAndFade from "../AnimateComponent/SlideAndFade";
import ScaleAndAppear from "../AnimateComponent/ScaleAndAppear";
import useOnScreen from "../hooks/useOnScreen";
import gsap from "gsap";

function Media({ index, title, description, image, url }) {
  const ref = useRef(null);
  const imgRef = useRef(null);
  const onScreen = useOnScreen(ref);
  const [isDone, setIsDone] = useState(false);

  const [matchesPhone, setMatchesPhone] = useState(
    window.matchMedia("(max-width: 500px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 500px)")
      .addEventListener("change", (e) => setMatchesPhone(e.matches));
  }, []);

  useLayoutEffect(() => {
    if (onScreen && !isDone) {
      let ctx = gsap.context(() => {
        gsap.from(imgRef.current, {
          x: -500,
          opacity: 0,
          skewY: 10,
          duration: 1,
          ease: "power2.out",
        });

        setIsDone(true);
      });
      return () => ctx.revert();
    }
  }, [onScreen]);

  return (
    <div className="component-media" ref={ref}>
      {!matchesPhone && index % 2 === 0 && (
        <a href={url} className="media-img desktop-img">
          <img
            src={image}
            alt=""
            ref={imgRef}
          />
        </a>
      )}

      <div className="description">
        <SlideAndFade>
          <h2>{title}</h2>
        </SlideAndFade>
        <ScaleAndAppear>
          <p>{description}</p>
        </ScaleAndAppear>
        <img src="assets/quote.png" alt="" className="quote" />
      </div>

      {!matchesPhone && index % 2 !== 0 && (
        <a href={url} className="media-img desktop-img">
          <img
            src={image}
            alt=""
            ref={imgRef}
          />
        </a>
      )}

      {matchesPhone && (
        <img src={image} alt="" className="media-img phone-img" ref={imgRef} onClick={() => (window.location.href = url)}/>
      )}
    </div>
  );
}

export default Media;
