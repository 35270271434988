import React, { useLayoutEffect, useRef, useState } from "react";
import useOnScreen from "../hooks/useOnScreen";
import gsap from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function ImageReveal({ src }) {
  const [isDone, setIsDone] = useState(false);
  const containerRef = useRef(null);
  const imgRef = useRef(null);
  const onScreen = useOnScreen(containerRef);

  useLayoutEffect(() => {
    if (onScreen && !isDone) {
      gsap.registerPlugin(ScrollTrigger);
      let rule = CSSRulePlugin.getRule(".reveal-container:after");
      let tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            toggleActions: "restart none none reset",
          },
        })
        .to(containerRef.current, {
          duration: 1,
          "--height": "100%",
          ease: "Power2.ease",
        })
        .to(containerRef.current, {
          duration: 1,
          "--height": "0%",
          ease: "Power2.ease",
        })
        .to(imgRef.current, { duration: 0.2, opacity: 1, delay: -1 })
        .from(imgRef.current, {
          duration: 1,
          scale: 1.4,
          ease: "Power2.easeInOut",
          delay: -1.2,
        });
      setIsDone(true);
    }
  }, [onScreen]);

  return (
    <div className="reveal-container" ref={containerRef}>
      <img
        className="reveal-img"
        ref={imgRef}
        src={src}
        alt="overlay-image"
      ></img>
    </div>
  );
}

export default ImageReveal;
