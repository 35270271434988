import React, { useRef, useLayoutEffect, useState } from "react";
import Button from "./Button";
import SlideAndFade from "../AnimateComponent/SlideAndFade";
import ScaleAndAppear from "../AnimateComponent/ScaleAndAppear";
import useOnScreen from "../hooks/useOnScreen";
import gsap from "gsap";

function Research({ index, title, description, image, link, by }) {
  const ref = useRef(null);
  const imgRef = useRef(null);
  const onScreen = useOnScreen(ref);
  const [isDone, setIsDone] = useState(false);
  useLayoutEffect(() => {
    if (onScreen && !isDone) {
      let ctx = gsap.context(() => {
        gsap.from(imgRef.current, {
          x: -500,
          opacity: 0,
          skewY: 10,
          duration: 1,
          ease: "power2.out",
        });

        setIsDone(true);
      });
      return () => ctx.revert();
    }
  }, [onScreen]);
  return (
    <div className="component-media" ref={ref}>
      <img
        src={image}
        alt=""
        className="media-img"
        ref={imgRef}
      />
      <div className="description">
        <SlideAndFade>
          <h5>{title}</h5>
        </SlideAndFade>
        <ScaleAndAppear>
          <p>{description}</p>
        </ScaleAndAppear>
        <div className="button-container">
          <Button text="Read More" link={link}/>
        </div>
      </div>
    </div>
  );
}

export default Research;
