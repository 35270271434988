import React from "react";
import research from "../data/research.json";
import ResearchComp from "../components/Research";
import Footer from "../components/Footer";
import useLocoScroll from "../hooks/useLocoScroll";
import RoundButton from "../components/RoundButton";

function Research() {
  useLocoScroll(true);
  return (
    <div className="page-contaner" id="main-container">
      <div className="wrapper research" id="main-container">
        <h1>Media Plan & Research</h1>
        {research.map((item, index) => (
          <ResearchComp
            index={index}
            title={item.title}
            description={item.description}
            image={item.img}
            link={item.link}
            by={item.by}
          />
        ))}
        <div className="round-btn-container">
          <RoundButton
            text={"Additional works can be found here"}
            width={"200px"}
            color={"black"}
            strokeWidth={"1"}
            link="https://drive.google.com/drive/folders/1a0wEhqjn09T3_iAUhH6nMznTr_uhi7Dg"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Research;
