import React, { useEffect } from "react";
import "./style.scss";
import portfolio from "../data/portfolio.json";
import { useRef, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import useLocoScroll from "../hooks/useLocoScroll";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useOnScreen from "../hooks/useOnScreen";
import SlideAndFade from "../AnimateComponent/SlideAndFade";
import ScaleAndAppear from "../AnimateComponent/ScaleAndAppear";
import Footer from "../components/Footer";
import ImageReveal from "../AnimateComponent/ImageReveal";
import ListImg from "../AnimateComponent/ListImg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import images from "../data/selectedWorks.json";

function Home() {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const portfolioRef = useRef(null);
  const worksRef = useRef(null);
  const onScreenWorks = useOnScreen(portfolioRef);
  const [isWorksDone, setIsworksDone] = useState(false);

  useLocoScroll(true);

  useLayoutEffect(() => {
    if (onScreenWorks && !isWorksDone) {
      let ctx = gsap.context(() => {
        gsap.from(".selected-work img", {
          duration: 0.5,
          opacity: 0,
          x: 500,
          stagger: 0.3,
          ease: "power1.out",
        });
        setIsworksDone(true);
      });
      return () => ctx.revert();
    }
  }, [onScreenWorks]);

  return (
    <div className="home" id="main-container">
      <div className="navbar-desc" ref={heroRef}>
        <SlideAndFade>
          <h1>Nghi Nguyen</h1>
        </SlideAndFade>
        <ScaleAndAppear>
          <p>
            I bring a fresh and innovative approach to advertising with expertise in crafting compelling content and executing impactful, data-driven campaigns.
          </p>
        </ScaleAndAppear>
      </div>
      <div className="banner">
        <ImageReveal src="assets/banner.webp" />
      </div>

      <div className="section" ref={aboutRef} id="about-me">
        <h3>About Me</h3>
        <div className="space-between">
          <SlideAndFade>
            <h2>
              Turning Data into Impact: Crafting the Future of Marketing with
              Creativity and Strategy
            </h2>
          </SlideAndFade>
          <ScaleAndAppear>
            <p>
              Hey, I'm Nghi! As a senior from the University of Texas at Austin
              working towards B.S. in Advertising, I bring a fresh and
              innovative perspective to marketing and strategy. My expertise
              lies in crafting compelling content, researching and analyzing
              data-driven insights, and executing impactful campaigns across
              diverse platforms.
              <br />
              <br />
              With hands-on experience in digital strategy and content creation,
              I excel in developing strategies that enhance brand visibility and
              drive business growth. My background includes creating engaging
              content, researching and creating media plans, and conducting
              trend analysis to deliver actionable insights. I am adept at
              leveraging digital tools and analytics to optimize marketing
              efforts and achieve measurable results.
              <br />
              <br />
              Certified in Google Digital Marketing and Amazon Advertising, and
              skilled in tools like Canva, Adobe Photoshop, and Tableau, I am
              well-prepared to tackle complex marketing challenges. My approach
              combines creativity with data-driven strategies to build effective
              marketing solutions and drive brand success.
              <br />
              <br />I am passionate about using my skills to contribute to
              dynamic marketing teams and help brands reach their goals through
              innovative and strategic marketing practices.
            </p>
          </ScaleAndAppear>
        </div>
      </div>

      <div className="section sec-portfolio" ref={portfolioRef}>
        <h3>Portfolio</h3>
      </div>
      {portfolio.map((item) => (
        <Link to={item.link}>
          <div className="portfolio">
            <span>
              <p>{item.number}</p>
              <h2>{item.name}</h2>
            </span>
            <img src="assets/arrow.png" alt="" />
          </div>
        </Link>
      ))}

      <div className="section" ref={worksRef}>
        <h3>Selected Works</h3>
        <ListImg images={images} />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
