import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

function RoundButton({ text, link, width, color, strokeWidth }) {
  const history = useHistory();
  return (
    <div
      className="round-button"
      style={{ width: width, height: width }}
      onClick={() => (window.location.href = link)}
    >
      <svg
        viewBox="0 0 149 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: width, height: width }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65.59 1.178C98.7171 -0.682999 134.303 11.9593 145.154 43.3141C156.518 76.1495 138.769 110.776 110.52 131.007C82.9176 150.774 45.053 155.142 18.4449 134.056C-5.70125 114.92 -0.818923 79.9716 9.48302 50.9357C18.724 24.8901 37.9971 2.72811 65.59 1.178Z"
          stroke={color}
          stroke-width={strokeWidth}
        />
      </svg>
      <svg
        viewBox="0 0 134 158"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: width, height: width }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M81.161 1.15702C111.499 3.28713 122.211 38.259 130.049 67.6445C136.245 90.8716 134.236 115.794 115.882 131.319C92.0463 151.479 59.2779 166.699 33.1643 149.591C3.91577 130.43 -5.4379 92.1572 5.32011 58.8871C16.1271 25.4657 46.1221 -1.30312 81.161 1.15702Z"
          stroke={color}
          stroke-width={strokeWidth}
        />
      </svg>

      <svg
        viewBox="0 0 153 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: width, height: width }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.63542 34.8301C12.1214 5.62489 48.5662 2.53691 78.9442 1.07756C102.956 -0.075939 126.893 7.14838 138.193 28.366C152.868 55.9201 160.828 91.1628 138.594 113.077C113.691 137.622 74.3059 138.686 44.0563 121.148C13.669 103.53 -6.16533 68.5603 3.63542 34.8301Z"
          stroke={color}
          stroke-width={strokeWidth}
        />
      </svg>

      <p>{text}</p>
    </div>
  );
}

export default RoundButton;
