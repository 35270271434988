import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./style.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Graphics from "./pages/Graphics";
import Research from "./pages/Research";
import Others from "./pages/Others";

function App() {
  return (
    <div className="Apps">
      <Router>
        <Switch>
          <Route exact path="/graphic">
            <Navbar />
            <Graphics />
          </Route>

          <Route exact path="/media">
            <Navbar />
            <Research />
          </Route>

          <Route exact path="/others">
            <Navbar />
            <Others />
          </Route>

          <Route exact path="/">
            <Navbar />
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
