import React, { useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

function NavbarPhone({ isOpen, setIsOpen }) {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (isOpen) {
        gsap.from(".navbar-phone", {
          x: 1000,
          skewY: 10,
          duration: 1,
          ease: "power2.out",
        });
      } else {
        gsap.to(".navbar-phone", {
          x: 1000,
          skewY: 10,
          duration: 1,
          ease: "power2.out",
        });
      }
    });

    return () => ctx.revert();
  }, [isOpen]);

  return (
    <div className="navbar-phone">
      <ul className="list-phone">
        <li>
          <Link to="/" onClick={() => setIsOpen(false)}>
            About
          </Link>
        </li>
        <li>
          <Link to="/graphic" onClick={() => setIsOpen(false)}>
            Graphic Design
          </Link>
        </li>
        <li>
          <Link to="/media" onClick={() => setIsOpen(false)}>
            Media plan
          </Link>
        </li>
        <li>
          <Link to="/others" onClick={() => setIsOpen(false)}>
            Others
          </Link>
        </li>
      </ul>

      <img src="assets/x.png" alt="" onClick={() => setIsOpen(!isOpen)} />
    </div>
  );
}

export default NavbarPhone;
