import React from "react";
import RoundButton from "./RoundButton";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <h4>Nghi Nguyen</h4>
      <ul>
        <a href="https://www.linkedin.com/in/nghi-nguyen-515a0a1b6">
          <li>Linkedin</li>
        </a>

        <a href="mailto:by.nghi.nguyen.16@gmail.com">
          <li>Email</li>
        </a>
      </ul>
      <RoundButton
        text={"Contact Me"}
        width={"200px"}
        color={"white"}
        strokeWidth={"0.3"}
      />
    </div>
  );
}

export default Footer;
