import React from "react";
import others from "../data/others.json";
import ResearchComp from "../components/Research";
import Footer from "../components/Footer";
import useLocoScroll from "../hooks/useLocoScroll";
import RoundButton from "../components/RoundButton";

function Others() {
  useLocoScroll(true);
  return (
    <div className="page-contaner" id="main-container">
      <div className="wrapper research">
        <h1>Other Works</h1>
        {others.map((item, index) => (
          <ResearchComp
            index={index}
            title={item.title}
            description={item.description}
            image={item.img}
            link={item.link}
            by={item.by}
          />
        ))}
        <div className="round-btn-container">
          <RoundButton
            text={"Additional works can be found here"}
            width={"200px"}
            color={"black"}
            strokeWidth={"1"}
            link="https://drive.google.com/drive/folders/1fPugK_l3a8u87VFaQphjkEyWzroNdpM4"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Others;
