import React, { useRef, useState, useLayoutEffect } from "react";
import useOnScreen from "../hooks/useOnScreen";
import gsap from "gsap";

function ListImg({images}) {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref);
  const [isDone, setIsDone] = useState(false);
  useLayoutEffect(() => {
    if (onScreen && !isDone) {
      let ctx = gsap.context(() => {
        gsap.from(".list-img img", {
          duration: 0.5,
          opacity: 0,
          x: 500,
          stagger: 0.3,
          ease: "power1.out",
        });
        setIsDone(true);
      });
      return () => ctx.revert();
    }
  }, [onScreen]);
  return (
    <div className="list-img" ref={ref}>
     {images.map((item) => (
        <a href={item.url}>
          <img src={item.src} alt="" />
        </a>
      ))}
    </div>
  );
}

export default ListImg;
