import React from "react";
import Media from "../components/Media";
import mediaData from "../data/media.json";
import Footer from "../components/Footer";
import useLocoScroll from "../hooks/useLocoScroll";
import ListImg from "../AnimateComponent/ListImg";
import RoundButton from "../components/RoundButton";
import canvaImages from "../data/canva.json";

function Graphics() {
  useLocoScroll(true);
  return (
    <div className="page-contaner" id="main-container">
      <div className="wrapper graphics">
        <h1>Adobe</h1>
        {mediaData.map((item, index) => (
          <Media
            index={index}
            title={item.title}
            description={item.description}
            image={item.img}
            url={item.url}
          />
        ))}
        <h1>Canva</h1>
        <ListImg images={canvaImages} />

        <div className="round-btn-container">
          <RoundButton
            text={"Additional works can be found here"}
            width={"200px"}
            color={"black"}
            strokeWidth={"1"}
            link="https://drive.google.com/drive/folders/1rOBUUANDDMCepk1zadAT_2sEHeebm5e8"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Graphics;
