import React from "react";

function Button({ text, link }) {
  return (
<a href={link}>
    <button className="button">
      {text}
    </button>
  </a>
  );
}

export default Button;
